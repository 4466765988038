html {
  --main-color: #024eed;
}

body {
  margin: 0;
  padding: 0 24px;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior-y: none;
}

.finske-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.logo-wrapper {
  width: 100%;
  max-width: 420px;
  color: var(--main-color);
}

.logo-wrapper svg {
  width: 100%;
  height: auto;
}

.link-wrapper {
  position: absolute;
  bottom: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-wrapper > a {
  width: 35px;
  height: 35px;
}

.link-wrapper > a > svg {
  width: 100%;
  height: 100%;
  fill: var(--main-color);
}

.link-wrapper > a:hover > svg {
  fill: #000;

  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.date {
  margin-top: 12px;
  font-size: 20px;
}
